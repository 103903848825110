<template>
  <div>
    <Header />
    <router-view />
  </div>
</template>

<script>
import Header from '@/components/landing/Header.vue';

export default {
  components: { Header },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="sass"></style>
