<template>
  <div class="block_container header__landing header__wrapper">
    <div class="logo-icon" @click="goToHome">
      <svg-icon name="arms" />
    </div>
    <nav :class="{ 'sandwich-open': isSandwichOpen }" class="header__nav">
      <div v-if="getCurrentUser" class="login_block_mobile">
        <div class="open-btn">
          <p class="userName">{{ userName }}</p>
        </div>
        <span class="position">{{ $t(`sections.${userData.type}`) }}</span>
      </div>
      <div class="profile-item" @click="goToProfile">
        <p>{{ $t(`main.my_profile`) }}</p>
      </div>
      <div
        class="header__nav-item"
        v-for="(item, i) in headerMenu"
        :key="item.id"
      >
        <router-link
          v-if="item.href"
          :to="item.href"
          class="nav__link"
          @click.native="closeSandwichMenu()"
        >
          {{ item.title }}
        </router-link>
        <div v-else class="nav__link" @click="dropdownToggle(item, i)">{{ item.title }}</div>

        <transition name="slide-toggle">
        <div v-if="item.children && item.childrenShow" class="header__nav-dropdown">
          <div class="header__nav-dropdown-menu">
            <div class="header__nav-dropdown-menu-item" v-for="(children) in item.children" :key="children.id">
              <router-link
                v-if="children.href"
                :to="children.href"
                class="nav__link"
                @click.native="closeSandwichMenu()"
              >
                {{ children.title }}
              </router-link>
            </div>
          </div>
        </div>
        </transition>
      </div>
      
      <div v-if="isSandwichOpen && !userData" class="sandwich__login-btns">
        <Button
          transparent
          medium
          isRightIcon
          iconName="exit-icon"
          @click.native="goToAuth('tour-operator')"
        >
          {{ $t(`header.btn.tour-operator`) }}
        </Button>
        <Button
          transparent
          medium
          isRightIcon
          iconName="exit-icon"
          @click.native="goToAuth('hotels')"
        >
          {{ $t(`header.btn.hotel`) }}
        </Button>
      </div>
      <Button
        v-else-if="isSandwichOpen && userData"
        class="btn_transparent sandwich-login"
        @click.native="logoutHandler"
      >
        {{ $t(`main.exit`) }}
        <svg-icon class="sandwich-icon" name="exit-icon" />
      </Button>
    </nav>
    <div v-if="getCurrentUser" class="option_user_menu">
      <div
        class="logout_menu__container"
        :class="isSubAuthMenuVisible ? '' : 'transparent-menu'"
      >
        <div
          class="login_block"
          @click="isSubAuthMenuVisible = !isSubAuthMenuVisible"
        >
          <div class="open-btn">
            <svg-icon
              class="bottom-arr"
              name="bottom-arr"
              :class="isSubAuthMenuVisible ? 'dropdown' : ''"
              @click="toggleMenu"
            />
            <p class="userName">{{ userName }}</p>
          </div>
          <span class="position">{{ $t(`sections.${userData.type}`) }}</span>
        </div>
        <div v-if="isSubAuthMenuVisible" class="menu-container">
          <ul class="menu_list">
            <li class="menu_list-item" @click="goToProfile">
              <div class="svg-icon__cont">
                <svg-icon class="sandwich-icon" name="men-icon" />
              </div>
              <p>{{ $t(`main.my_profile`) }}</p>
            </li>
            <li class="menu_list-item exit-item" @click="logoutHandler">
              <div class="svg-icon__cont">
                <svg-icon class="sandwich-icon" name="exit-icon" />
              </div>
              <p>{{ $t(`main.exit`) }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-else class="login__block">
      <Button
        transparent
        medium
        isRightIcon
        iconName="exit-icon"
        @click.native="goToAuth('tour-operator')"
      >
        {{ $t(`header.btn.tour-operator`) }}
      </Button>
      <Button
        transparent
        medium
        isRightIcon
        iconName="exit-icon"
        @click.native="goToAuth('hotels')"
      >
        {{ $t(`header.btn.hotel`) }}
      </Button>
    </div>

    <div class="sandwich-menu" @click="toggleSandwichMenu()">
      <Hamburger :is-cross="isSandwichOpen" />
      <!-- <svg-icon v-if="!isSandwichOpen" class="sandwich-icon" name="sandwich" />
      <svg-icon v-else class="sandwich-icon" name="cross" /> -->
    </div>
  </div>
</template>

<script>
import Hamburger from '@/elements/Hamburger.vue'
import { mapGetters } from 'vuex'
import { getFromLS } from '@/library/helpers'

export default {
  components: { Hamburger },

  data() {
    return {
      isSandwichOpen: false,
      activeParentIdx: null,
      headerMenu: [
        {
          id: 'services',
          title: 'Послуги',
          href: null,
          childrenShow: false,
          children: [
            {
              id: 'categorization',
              title: 'Категоризація',
              href: '/questions/hotel-categorization-procedure',
            },
            {
              id: 'licensing',
              title: 'Ліцензування',
              href: '/questions/tour-operator-license-procedure',
            },
          ]
        },
        {
          id: 'register',
          title: 'Реєстр',
          href: '/registry',
        },
        {
          id: 'statistic',
          title: 'Статистика',
          href: { name: 'landing-page', hash: '#statistic' },
        },
        {
          id: 'questions',
          title: 'Часті запитання',
          href: { name: 'landing-page', hash: '#questions' },
        },
        {
          id: 'news',
          title: 'Новини',
          href: { name: 'landing-page', hash: '#news' },
        },
        {
          id: 'contacts',
          title: 'Контакти',
          href: '/contacts',
        },
      ],
      userData: getFromLS('currentUser'),
      userRole: getFromLS('currentRole'),
      isSubAuthMenuVisible: false,
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getCurrentProfilePath']),
    
    userName() {
      let userName = 'test user'

      if(this.getCurrentUser && this.getCurrentUser.name_of_legal_entity) {
        userName = this.getCurrentUser.name_of_legal_entity
      } else if(this.getCurrentUser && this.getCurrentUser.full_name) {
        userName = this.getCurrentUser.full_name
      }

      return userName
    },
  },
  watch: {
    isSandwichOpen: function () {
      if (this.isSandwichOpen) {
        document.documentElement.style.overflow = 'hidden'
        return
      }
      document.documentElement.style.overflow = 'auto'
    },
  },
  methods: {
    logoutHandler() {
      // this.$router.push({ name: 'auth-password' });
      this.$store.dispatch('userLogout')
    },

    goToAuth(name) {
      document.documentElement.style.overflow = 'auto'
      this.$router.push({ name: `auth-key-${name}` })
    },

    goToHome() {
      this.$router.push({ name: 'landing-page' })
      this.closeSandwichMenu()
      this.closeDropdown()
    },
    toggleSandwichMenu() {
      this.isSandwichOpen = !this.isSandwichOpen
    },
    closeSandwichMenu() {
      this.isSandwichOpen = false
      this.closeDropdown()
    },
    toggleMenu() {
      this.isSubAuthMenuVisible = !this.isSubAuthMenuVisible
    },
    goToProfile() {
      this.$router.push({ name: this.getCurrentProfilePath })
    },
    dropdownToggle(item, i) {
      item.childrenShow = !item.childrenShow;
      this.activeParentIdx = item.childrenShow ? i : null
    },
    closeDropdown() {
      if(this.activeParentIdx !== null) {
        this.headerMenu[this.activeParentIdx].childrenShow = false
      }
    },
  },
}
</script>

<style lang="sass" scoped>

.logo-icon
  min-width: 4.8rem

.slide-toggle-enter-active
   transition-duration: 0.3s
   transition-timing-function: ease-in

.slide-toggle-leave-active
   transition-duration: 0.3s
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1)

.slide-toggle-enter-to,
.slide-toggle-leave
   max-height: 100px
   overflow: hidden

.slide-toggle-enter,
.slide-toggle-leave-to
   overflow: hidden
   max-height: 0

.header__nav-dropdown
  position: absolute
  background: #fff
  width: 100%
  left: 0
  margin-top: 52px
  z-index: 999
  border-bottom: 1px solid #F5F5F5

  @include s
    position: relative
    left: initial
    margin-top: 0

  &-menu
    width: 100%
    display: flex
    gap: 32px
    max-width: 1920px
    background: #fff
    padding: 3rem 6.4rem 3rem 160px

    @include s
      gap: 24px
      padding: 2rem 1.6rem 2rem 1.6rem
      flex-direction: column

  .nav__link
    @include xs
      padding-left: 0 !important

.header__nav-item
  @include s
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 100%

.header__nav .login_block_mobile
  display: none
  @include s
    display: flex
    flex-wrap: wrap
    justify-content: right

.header__landing
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  gap: 5rem
  position: sticky
  z-index: 102
  top: 0
  background: linear-gradient(282deg, #FCFBF2 -2.13%, #F9F7E5 -15.44%, #E4F0FF 69.05%)
  transition: all .3s ease 0s

  @include s
    background: #e4f0ff

.menu_list-item
  display: flex

.menu_list
  position: absolute
  padding: 10px
  li:first-child
    margin-bottom: 14px

div .menu_list
  @include xs
    position: static
    border: none

.header__nav
  display: flex
  justify-content: space-between
  align-items: center
  gap: 5rem

  @media screen and (max-width: 1280px)
    gap: 3rem

  @include s
    text-align: center
  @include xs
    text-align: left

.profile-item
  padding-left: 1.6rem
  display: none
  width: 100%
  @include s
    display: inline-block
    width: auto
    padding-left: 0
  @include xs
    width: 100%
    padding-left: 1.6rem

.mobile_login__menu
  @include xs
    width: 100%
.bottom-arr.dropdown
  transform: rotate(180deg)
  transition: all 0.4s ease

.login__block
  @include s
    display: none

  .btn
    white-space: nowrap

    @media screen and (max-width: 1280px)
      padding: 10px 16px
      font-size: 13px

.option_user_menu
  transform: translateY(12px)
  @include s
    display: none
.header__nav
  @include s
    padding-top: 2.4rem
    display: flex
    flex-direction: column
    justify-content: start
    gap: 2.4rem
    position: fixed
    background-color: white
    width: 100%
    height: 100%
    z-index: 100
    left: 0
    bottom: 0
    top: -100%
    transition: top .8s
    transition-timing-function: ease-in-out

.bottom-arr
  width: 15px
  height: 15px
  margin-right: 10px

.header__nav .nav__link
  color: black
  white-space: nowrap

  @media screen and (max-width: 1280px)
    font-size: 13px

  @include s
    width: 100%
    position: relative
    padding-left: 0
    @include xs
      padding-left: 1.6rem

.header__nav a::after
  @include s
    content: ""
    position: absolute
    width: 100%
    height: 100%
    top: 1rem
    left: 0
.svg-icon__cont
  width: 24px
  height: 24px
  display: flex
  justify-content: center
  align-items: center
  border-radius: 3px
  background-color: #F3F3F3
  margin-right: 6px
.open-btn
  display: flex
  align-items: center
  margin-left: auto
.sandwich-menu
  margin-left: auto
  cursor: pointer

.sandwich-open
  top: 12.8rem
  transition: top .8s
  transition-timing-function: ease-in-out
  @include s
    top: 11.2rem
  @include xs
    top: 6.8rem

.sandwich-icon.svg-icon.icon-men-icon, .sandwich-icon.svg-icon.icon-exit-icon
  width: 11px
  height: 18px

.sandwich-login
  margin-top: 6rem
  display: none
  @include s
    display: flex

.router-link-active
  border-bottom: 1px solid $black
  @include s
    border-bottom: none
.userName
  font-size: 16px
  font-style: normal
  font-weight: 400
  line-height: 24px
  text-align: end
.position
  font-size: 12px
  font-style: normal
  font-weight: 400
  line-height: 24px
  color: #7F7F7F
  display: block
  width: 100%
  text-align: right
.login_block
  display: flex
  width: 100%
  // justify-content: end
  flex-wrap: wrap
  background-color: white
  border-top-left-radius: 5px
  border-top: 1px solid #D5D5D6
  border-left: 1px solid #D5D5D6
  border-right: 1px solid #D5D5D6
  padding: 14px 14px 12px
  cursor: pointer
  @include s
    border: none
.logout_menu__container
  display: flex
  flex-wrap: wrap
  justify-content: end
  width: 347px
  height: 75px
  @include m
    width: 247px

.menu-container
  width: 100%
.menu_list
  position: absolute
  width: 347px
  background-color: white
  border: 1px solid #D5D5D6
  border-bottom-left-radius: 5px
  border-bottom-right-radius: 5px
  cursor: pointer
  @include m
    width: 247px

.transparent-menu .login_block
  background-color: transparent
  border: 1px solid transparent
.exit-item
  position: relative
  z-index: 9999

.nav__link
  cursor: pointer
  display: inline
  text-underline-offset: 4px

.nav__link.router-link-active
  border-bottom: none
  text-decoration: underline

.nav__link.router-link-active:hover
  // margin-top: -1px
  border-bottom: none

.nav__link:hover
  text-decoration: underline
  // border-bottom: 1px solid #000000
  // margin-top: 1px

.sandwich__login-btns
  display: none
  @include s
    display: flex
    gap: 16px
  @include xs
    flex-direction: column

  .btn__main
    @include s
      min-width: 260px
</style>

