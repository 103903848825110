<template>
  <span id="x" :class="{ clicked: isCross }"></span>
</template>

<script>
export default {
  props: {
    isCross: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass" scoped>
span
  display: block
  width: 30px
  height: 30px
  cursor: pointer
  border-radius: 1px
  margin: 15% auto
  -webkit-transition: all .3s ease, -webkit-transform .2s ease
          transition: all .3s ease, transform .2s ease
  background: -webkit-linear-gradient(top, transparent 0%, transparent 20%, #2E313C 20%, #2E313C 23%, transparent 23%, transparent 48%, #2E313C 48%, #2E313C 50%, transparent 47%, transparent 76%, #2E313C 76%, #2E313C 78%, transparent 78%), -webkit-linear-gradient(transparent, transparent)
  background: linear-gradient(to bottom, transparent 0%, transparent 20%, #2E313C 20%, #2E313C 23%, transparent 23%, transparent 48%, #2E313C 48%, #2E313C 50%, transparent 47%, transparent 76%, #2E313C 76%, #2E313C 78%, transparent 78%), linear-gradient(transparent, transparent)
  transform: scale(0.9)

span:active, span:hover
  -webkit-transform: scale(0.9)
      -ms-transform: scale(0.9)
          transform: scale(0.9)

span.clicked
  background: -webkit-linear-gradient(135deg, transparent 0%, transparent 48%, #2E313C 49%, #2E313C 51%, transparent 51%, transparent 100%), -webkit-linear-gradient(45deg, transparent 0%, transparent 48%, #2E313C 49%, #2E313C 51%, transparent 51%, transparent 100%)
  background: linear-gradient(-45deg, transparent 0%, transparent 48%, #2E313C 49%, #2E313C 51%, transparent 51%, transparent 100%), linear-gradient(45deg, transparent 0%, transparent 48%, #2E313C 49%, #2E313C 51%, transparent 51%, transparent 100%)
  -webkit-transform: rotate(180deg)
      -ms-transform: rotate(180deg)
          transform: rotate(180deg)

span.clicked:active, span.clicked:hover
  -webkit-transform: scale(0.9) rotate(180deg)
      -ms-transform: scale(0.9) rotate(180deg)
</style>
